<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title>Créer Un formulaire</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Nom du formulaire (Planning)</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="libelleForm"
                  v-model="form.libelleForm"
                  outlined
                  dense
                  placeholder="Nom du formulaire (Planning)"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">ID Project Planning</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="ID Project Planning"
                  v-model="form.idApplicationName"
                  outlined
                  dense
                  placeholder="ID Project Planning"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Id Modèle CraftMyPDF</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="Id Modèle CraftMyPDF"
                  v-model="form.idTemplateCraftMyPDF"
                  outlined
                  dense
                  placeholder="Id Modèle CraftMyPDF"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col offset-md="3" cols="9">
                <v-alert
                  style="margin-top:20px;margin-bottom:0px"
                  :color="statutAlert === 'INFO' ? 'primary' : 'error'"
                  v-show="message"
                  dense
                  dismissible
                  outlined
                  prominent
                  :type="statutAlert === 'INFO' ? 'info' : 'error'"
                  >{{ message }}</v-alert
                >
              </v-col>
              <v-col offset-md="3" cols="12">
                <v-btn color="primary" @click="saveForm">
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiEmailOutline, mdiCameraImage, mdiWeb, mdiApplication, mdiApi } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FormAPI from '../../api/FormAPI'
import handleErrorResponse from '../../utils'
export default {
  methods: {
    saveForm() {
      this.statutAlert = 'INFO'
      this.message = 'La création du formulaire est en cours ...'
      FormAPI.addForms(this.form)
        .then(response => {
          console.log(response)
          this.statutAlert = 'INFO'
          this.message = 'Le formulaire a été créé avec succès ! '
          setTimeout(() => this.$router.push({ path: '/forms' }), 1500)
        })
        .catch(e => {
          console.log('error', e)
          this.statutAlert = 'ERROR'
          this.message = (e && e.message) || e
        })
    },
  },
  data() {
    return {
      form: {
        idApplicationName: null,
        libelleForm: '',
        idTemplateCraftMyPDF: null
      },
      message: '',
      statutAlert: 'ERROR',
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiWeb,
        mdiApplication,
        mdiApi,
        mdiCameraImage,
      },
    }
  },
}
</script>
